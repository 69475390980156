<template>
  <div class="landing-page">
    <!-- Hero Section -->
    <section class="hero">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5">
            <div class="profile-image-container">
              <img src="@/assets/profilepic.png" alt="Christian Heyerdahl-Larsen" class="profile-image">
            </div>
          </div>
          <div class="col-md-7">
            <div class="hero-content">
              <h1>Christian Heyerdahl-Larsen</h1>
              <h2>Professor of Finance at BI Norwegian Business School</h2>
              <p class="lead">
                Exploring the intersection of asset pricing and investor heterogeneity, with a focus on how individual differences shape market dynamics and financial outcomes.
              </p>
              <div class="social-links">
                <!-- <a href="#" class="social-link" title="Google Scholar">
                  <i class="bi bi-google"></i>
                </a> -->
                <a href="https://www.linkedin.com/in/christian-heyerdahl-larsen-385b93/" target="_blank" class="social-link" title="LinkedIn">
                  <i class="bi bi-linkedin"></i>
                </a>
                <!-- <a href="#" class="social-link" title="Twitter">
                  <i class="bi bi-twitter"></i>
                </a> -->
                <a href="mailto:christian.heyerdahl-larsen@bi.no" class="social-link" title="Email">
                  <i class="bi bi-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Latest Updates Section -->
    <section class="latest-updates">
      <div class="container">
        <h1>Latest Updates</h1>
        <div class="updates-section">
          <div v-for="(update, index) in latestUpdates" :key="index" class="update-card">
            <div class="update-content">
              <div class="update-date">{{ update.date }}</div>
              <div class="update-title">{{ update.title }}</div>
              <div class="update-description">{{ update.description }}</div>
            </div>
            <div class="update-links" v-if="update.link">
              <a :href="update.link" target="_blank" class="link-button">Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  data() {
    return {
      latestUpdates: [
        {
          date: 'January 2025',
          title: 'Discussion at the AFA Annual Meeting',
          description: 'I will be discussing Volatility Disagreement and Asset Prices by Adem Atmaz and Andrea Buffa',
          link: 'https://www.aeaweb.org/conference/2025/program/1867?q=eNo1jEEKgCAQRe_y1y5q0cZzdAHRIYVKcaQQ8e5NWLv3Hp_fwMQc4rnWRNDtV2jM6ArWFNpiruJQMMzRDiyUDyFPlbIzfpfkzDcr4aBBV6D7fc0pSVgm9P5cMFD5I1o'
        },
        {
          date: 'December 2024',
          title: 'Asset Pricing with the Awareness of New Priced Risks',
          description: 'I will be presenting the paper "Asset Pricing with the Awareness of New Priced Risks" and the "The CUHK-RAPS-RCFS Conference on Asset Pricing and Corporate Finance".',
          link: 'https://www.cuhk.edu.hk/fin/event/CUHK-RAPS-RCFS-Conference2024/Programme.html'
        },
        {
          date: 'November 2024',
          title: 'Demand Disagreement',
          description: 'A new version of the paper "Demand Disagreement" is now available on SSRN. The paper focuses on the role of demand disagreement for bond yields.',
          link: 'https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3092366'
        }
      ]
    }
  }
}
</script>

<style scoped>
.landing-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.hero {
  padding: 2rem 0;
  margin-bottom: 2rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.align-items-center {
  align-items: center;
}

.col-md-5, .col-md-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.profile-image-container {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.profile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.hero-content {
  padding: 1rem;
  color: var(--text-color, #ffffff);
  text-align: center;
}

h1 {
  color: var(--text-color, #ffffff);
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  margin-bottom: 1rem;
  text-align: center;
}

h2 {
  color: var(--text-secondary, #b3b3b3);
  font-size: clamp(1.1rem, 3vw, 1.5rem);
  margin-bottom: 1rem;
}

.lead {
  color: var(--text-secondary, #cccccc);
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.social-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--card-bg, #2a2a2a);
  color: var(--text-color, #ffffff);
  font-size: 1.2rem;
  transition: all 0.3s ease;
  text-decoration: none;
}

.social-link:hover {
  background-color: var(--card-hover-bg, #333333);
  transform: translateY(-2px);
  color: var(--text-color, #ffffff);
}

.updates-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 800px;
}

.update-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 8px;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.update-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.update-content {
  color: var(--text-color, #ffffff);
}

.update-date {
  color: var(--accent-color, #4a90e2);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.update-title {
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--text-color, #ffffff);
}

.update-description {
  color: var(--text-secondary, #cccccc);
  line-height: 1.6;
  margin-bottom: 1rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.update-links {
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;
}

.link-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: var(--accent-color, #4a90e2);
  color: white;
  display: inline-block;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
}

@media (min-width: 768px) {
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .hero-content {
    text-align: left;
    padding: 2rem;
  }

  .social-links {
    justify-content: flex-start;
  }

  .profile-image-container {
    margin-bottom: 0;
  }
}

/* Light mode styles */
:root[data-theme="light"] .hero-content {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] h1 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] h2 {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] .lead {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] .social-link {
  background-color: var(--card-bg, #f5f5f5);
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .social-link:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .update-card {
  background-color: var(--card-bg, #f5f5f5);
}

:root[data-theme="light"] .update-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] .update-content {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .update-title {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .update-description {
  color: var(--text-secondary, #666666);
}
</style>
